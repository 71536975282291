import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { AuthContext } from '../context/AuthContext'

export default function Logout() {
    const navigate = useNavigate()
    let auth = useContext(AuthContext)
    useEffect(() => {
        auth.logout()
    }, [])

    useEffect(() => {
        // if (auth.isLoggedIn) {
        //     navigate(`/home`)
        // } else {
        //     navigate(`/login`)
        // }
        navigate(`/login`)
    }, [auth.isLoggedIn])

    return (
        <>
            <div style={{ textAlign: 'center' }}>Logging out...</div>
        </>
    )
}
